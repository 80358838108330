export const initBoosts = {
  boosts: [
    {
      id: 1,
      type: 'farming.autocollect',
      title: 'Farming autocollect',
      price: {
        amount: '0',
      },
      quantity: null,
      isRefundable: false,
      isQuantifiable: false,
      packs: null,
    },
    {
      id: 2,
      type: 'clicker.games',
      title: 'Additional clicker games',
      price: {
        amount: '0',
      },
      quantity: 0,
      isRefundable: false,
      isQuantifiable: true,
      packs: [],
    },
    {
      id: 3,
      type: 'clicker.retry',
      title: 'Load saved game',
      price: {
        amount: '0',
      },
      quantity: 0,
      isRefundable: false,
      isQuantifiable: true,
      packs: [],
    },
  ],
}
