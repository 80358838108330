<template>
  <b-card>
    <b-card-title
      title-tag="h2"
      class="font-weight-bold mb-1"
    >
      {{ title }}
    </b-card-title>

    <!-- form -->
    <validation-observer ref="limitsValidation">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
      >
        <!--        <b-form-group-->
        <!--          label="Day Limit In"-->
        <!--          label-for="daily-in"-->
        <!--        >-->
        <!--          <validation-provider-->
        <!--            #default="{ errors }"-->
        <!--            name="daily-in"-->
        <!--            rules="required"-->
        <!--          >-->
        <!--            <b-form-input-->
        <!--              id="daily-in"-->
        <!--              v-model="dailyIn"-->
        <!--              :state="errors.length ? false : null"-->
        <!--              type="number"-->
        <!--              name="daily-in"-->
        <!--            />-->
        <!--            <small class="text-danger">{{ errors[0] }}</small>-->
        <!--          </validation-provider>-->
        <!--        </b-form-group>-->

        <b-form-group
          label="Day Limit Out"
          label-for="daily-out"
        >
          <validation-provider
            #default="{ errors }"
            name="daily-out"
            rules="required"
          >
            <b-form-input
              id="daily-out"
              v-model="dailyOut"
              :state="errors.length ? false : null"
              type="number"
              name="daily-out"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!--        <b-form-group-->
        <!--          label="Month Limit In"-->
        <!--          label-for="monthly-in"-->
        <!--        >-->
        <!--          <validation-provider-->
        <!--            #default="{ errors }"-->
        <!--            name="monthly-in"-->
        <!--            rules="required"-->
        <!--          >-->
        <!--            <b-form-input-->
        <!--              id="monthly-in"-->
        <!--              v-model="monthlyIn"-->
        <!--              :state="errors.length ? false : null"-->
        <!--              type="number"-->
        <!--              name="monthly-in"-->
        <!--            />-->
        <!--            <small class="text-danger">{{ errors[0] }}</small>-->
        <!--          </validation-provider>-->
        <!--        </b-form-group>-->

        <b-form-group
          label="Month Limit Out"
          label-for="monthly-out"
        >
          <validation-provider
            #default="{ errors }"
            name="monthly-out"
            rules="required"
          >
            <b-form-input
              id="monthly-out"
              v-model="monthlyOut"
              :state="errors.length ? false : null"
              type="number"
              name="monthly-out"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button
          block
          type="submit"
          variant="primary"
          class="mt-2"
          :disabled="requestInProgress"
          @click="save"
        >
          Save
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import {
  BButton, BCard, BCardTitle, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import validationError from '@/mixins/validationError'
import waitRequest from '@/mixins/waitRequest'
// eslint-disable-next-line
import { required } from '@validations'

export default {
  name: 'EditCeFiLimitsModal',
  components: {
    BButton,
    BCard,
    BFormGroup,
    BForm,
    ValidationProvider,
    BFormInput,
    BCardTitle,
  },
  mixins: [validationError, waitRequest],
  props: {
    title: {
      type: String,
      default: 'Edit Limits',
    },
    type: {
      type: String,
      default: 'cefi',
    },
  },
  emits: ['close'],
  data() {
    return {
      dailyIn: '',
      dailyOut: '',
      monthlyIn: '',
      monthlyOut: '',
      userLimit: {},
      userId: '',
    }
  },

  mounted() {
    this.initState()
  },

  methods: {
    ...mapActions({
      fetchUserLimits: 'users/fetchUserLimits',
      updateUserLimits: 'users/updateUserLimits',
    }),
    async initState() {
      this.userId = this.$route.params.id
      this.getLimits(this.userId).then(() => {
        // this.dailyIn = this.userLimit[this.type].dayLimitIn
        this.dailyOut = this.userLimit[this.type].dayLimitOut
        // this.monthlyIn = this.userLimit[this.type].monthLimitIn
        this.monthlyOut = this.userLimit[this.type].monthLimitOut
      })
    },
    getLimits(id) {
      return this.waitRequest(() => this.fetchUserLimits({ id })
        .then(response => {
          this.userLimit = response.data
        })
        .catch(this.checkErrors))
    },
    save() {
      const params = {
        type: this.type,
        dayLimitIn: Number(this.dailyIn),
        dayLimitOut: this.dailyOut,
        monthLimitIn: this.monthlyIn,
        monthLimitOut: this.monthlyOut,
      }
      this.waitRequest(() => this.updateUserLimits({ id: this.userId, params })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Limits changed',
              icon: 'UserPlusIcon',
              variant: 'success',
            },
          })
          this.$emit('close')
        })
        .catch(this.checkErrors))
    },

  },
}
</script>

<style scoped lang="scss"></style>
