import {nFormat} from "@/tools/util";

const chartColors = {
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
}

export const baseDonatChart = {
  series: [85, 16, 50, 50],
  chartOptions: {
    labels: ['Product 1', 'Product 2', 'Product 3 ', 'Product 4'],

    colors: [
      chartColors.donut.series1,
      chartColors.donut.series5,
      chartColors.donut.series3,
      chartColors.donut.series2,
    ],
    
    tooltip: {
      y: {
        formatter(val) {
          // eslint-disable-next-line radix
          return `${nFormat(parseInt(val))}`
        },
      },
    },

    legend: {
      show: true,
      position: 'bottom',
      fontSize: '14px',
      fontFamily: 'Montserrat',
    },

    dataLabels: {
      enabled: true,
      formatter(val) {
        return nFormat(val)
      },
    },
    
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              fontSize: '2rem',
              fontFamily: 'Montserrat',
            },
            value: {
              fontSize: '1rem',
              fontFamily: 'Montserrat',
              formatter(val) {
                // eslint-disable-next-line radix
                return `${nFormat(parseInt(val))}`
              },
            },
            total: {
              show: true,
              fontSize: '1.5rem',
              label: 'Total',
              formatter(val) {
                return nFormat(val.config?.series?.reduce((acc, item) => acc + item, 0) || 0)
              },
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 992,
        options: {
          chart: {
            height: 250,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            height: 250,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '1.5rem',
                  },
                  value: {
                    fontSize: '1rem',
                  },
                  total: {
                    fontSize: '1.5rem',
                  },
                },
              },
            },
          },
          legend: {
            show: true,
          },
        },
      },
    ],
  },
}
