import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { debounce } from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import doCopy from '@/mixins/doCopy'
import { statusTypes, transactionTypes } from '@/views/apps/wallet-transactions/config/transactionTypes'
import { formatLongString, formattedDate } from '@/tools/util'
import store from '@/store'

export default {
  mixins: [doCopy],
  directives: {
    Ripple,
  },
  data() {
    return {
      userTelegramName: '',
      userAddress: '',
      selectAsset: {},

      selectType: '',
      typesOptions: ['', ...Object.values(transactionTypes)],

      selectStatus: '',
      statusOptions: ['', ...Object.values(statusTypes)],
    }
  },
  computed: {
    ...mapGetters({ currency: 'cryptoCurrency/cryptoCurrency' }),

    assetOptionsList() {
      return this.searchedCurrencyList.length ? this.searchedCurrencyList : this.currency.value
    },

    direction() {
      return store.state.appConfig.isRTL
    },
  },

  methods: {
    formatLongString,
    formattedDate,
    ...mapActions({
      fetchTransactions: 'transactions/fetchTransactions',
      fetchSearchedCryptoCurrency: 'cryptoCurrency/fetchSearchedCryptoCurrency',
      fetchCryptoCurrency: 'cryptoCurrency/fetchCryptoCurrency',
    }),

    transactionsDataAdapter(item) {
      const createdAt = item.createdAt ? this.formattedDate(item.createdAt) : ''

      const asset = this.renderAsset(item)

      const amount = {
        text: this.renderAmount(item),
        theme: (item.type === 'deposit' || (item.type === 'referral' && item.toUser) || item.type === 'deposit_nft' || item.type === 'farming' || item.type === 'clicker' || item.type === 'bonus' || item.type === 'daily_checkin' || item.type === 'task') ? 'text-success' : 'text-danger',
      }

      const fee = `${item.feeAmount || ''} ${item.feeCoin?.symbol || ''}`

      const user = {
        action: () => this.renderUserLink(item),
        name: this.renderUserName(item),
      }

      const status = {
        theme: this.getStatusTheme(item),
        text: this.getStatusText(item),
      }

      const toAddress = {
        text: this.formatLongString(item.toAddress || ''),
        raw: item.toAddress || '',
      }

      const checkUrl = {
        text: this.formatUrl(item.checkUrl),
        raw: item.checkUrl || '',
      }

      return {
        ...item,
        createdAt,
        asset,
        amount,
        fee,
        user,
        status,
        toAddress,
        checkUrl,
      }
    },

    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })

      return this.getTransactions(query)
    },

    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })

      return this.getTransactions(query)
    },

    search() {
      const query = this.createQueryConfig({
        userAddress: this.userAddress,
        userTelegramName: this.userTelegramName,
        page: 1,
      })

      return this.getTransactions(query)
    },

    searchAsset(asset) {
      this.searchCurrency(asset)
    },

    changeAsset({ id = '' } = {}) {
      const query = this.createQueryConfig({ coinId: id })

      return this.getTransactions(query)
    },

    changeType(type) {
      const query = this.createQueryConfig({ type })

      return this.getTransactions(query)
    },

    changeStatus(status) {
      const query = this.createQueryConfig({ status })

      return this.getTransactions(query)
    },

    searchCurrency: debounce(function(search) {
      return this.waitRequest(() => {
        return this.fetchSearchedCryptoCurrency(search)
          .then(response => {
            this.searchedCurrencyList = response.data?.items || []
          })
          .catch(this.checkErrorsAlert)
      })
    }, 500),

    statusVariant(
      status,
      newParam = {
        [statusTypes.pending]: {
          theme: 'light-primary',
          text: 'pending',
        },
        [statusTypes.success]: {
          theme: 'light-success',
          text: 'success',
        },
        [statusTypes.reject]: {
          theme: 'light-info',
          text: 'reject',
        },
        [statusTypes.error]: {
          theme: 'light-danger',
          text: 'error',
        },
      },
    ) {
      const statusConfig = newParam

      return statusConfig[status] || statusConfig.success
    },

    getStatusText(item) {
      const currentStatus = this.statusVariant(item.status)
      return currentStatus.text
    },
    getStatusTheme(item) {
      const currentStatus = this.statusVariant(item.status)
      return currentStatus.theme
    },

    renderAsset(row) {
      switch (row.type) {
        case transactionTypes.deposit:
        case transactionTypes.deposit_nft:
        case transactionTypes.farming:
        case transactionTypes.clicker:
        case transactionTypes.task:
        case transactionTypes.daily_checkin:
          return {
            image: row?.receivedCoin?.image,
            text: `${row?.receivedCoin?.name || ''} ${row.network ? '/ ' + row.network.network : ''}`,
          }
        case transactionTypes.referral:
          return {
            image: !row.toUser ? row?.paidCoin?.image : row?.receivedCoin?.image,
            text: `${!row.toUser ? row?.paidCoin?.name : row?.receivedCoin?.name || ''} ${row.network ? '/ ' + row.network.network : ''}`,
          }
        case transactionTypes.withdrawal:
        case transactionTypes.withdrawal_nft:
        case transactionTypes.transfer:
          return {
            image: row?.paidCoin?.image,
            text: `${row?.paidCoin?.name || ''} ${row.network ? '/ ' + row.network.network : ''}`,
          }
        case transactionTypes.bonus:
          return {
            image: row?.receivedCoin?.image,
            text: `${row?.receivedCoin?.name || ''} ${row.network ? '/ ' + row.network.network : ''}`,
          }
        default:
          return {
            image: row?.paidCoin?.image,
            text: `${row?.paidCoin?.name || ''}  ${row.network ? '/ ' + row.network.network : ''}`,
          }
      }
    },

    renderAmount(row) {
      switch (row.type) {
        case transactionTypes.deposit:
        case transactionTypes.deposit_nft:
        case transactionTypes.farming:
        case transactionTypes.clicker:
        case transactionTypes.task:
        case transactionTypes.daily_checkin:
          return `+${row.receivedAmount || ''} ${row?.receivedCoin?.symbol || ''}`
        case transactionTypes.referral:
          if (!row.toUser) return `-${row?.paidAmount || ''} ${row?.paidCoin?.symbol || ''}`
          return `+${row.receivedAmount || ''} ${row?.receivedCoin?.symbol || ''}`
        case transactionTypes.bonus:
          return `+${row?.receivedAmount || ''} ${row?.receivedCoin?.symbol || ''}`  
        case transactionTypes.withdrawal:
        case transactionTypes.withdrawal_nft:
          return `-${row?.paidAmount || ''} ${row?.paidCoin?.symbol || ''}`
        case transactionTypes.transfer:
          return `-${row?.paidAmount || ''} ${row?.paidCoin?.symbol || ''}`
        default:
          return `${row?.paidAmount || ''} ${row?.paidCoin?.symbol || ''}`
      }
    },
    renderUserName(row) {
      switch (row.type) {
        case transactionTypes.deposit:
        case transactionTypes.deposit_nft:
        case transactionTypes.referral:
        case transactionTypes.farming:
        case transactionTypes.clicker:
        case transactionTypes.task:
        case transactionTypes.daily_checkin:
          if (!row.toUser) return row?.fromUser?.firstName || row?.fromUser?.lastName ? `${row?.fromUser?.firstName || ''} ${row?.fromUser?.lastName || ''}` : row?.fromUser?.telegramUsername
          return row?.toUser?.firstName || row?.toUser?.lastName ? `${row.toUser?.firstName || ''} ${row.toUser?.lastName || ''}` : row?.toUser?.telegramUsername
        case transactionTypes.withdrawal:
        case transactionTypes.withdrawal_nft:
          if (!row.fromUser) return ''
          return row?.fromUser?.firstName || row?.fromUser?.lastName ? `${row?.fromUser?.firstName || ''} ${row?.fromUser?.lastName || ''}` : row?.fromUser?.telegramUsername
        case transactionTypes.bonus:
          if (!row.toUser) return ''
          return row?.toUser?.firstName || row?.toUser?.lastName ? `${row?.toUser?.firstName || ''} ${row?.toUser?.lastName || ''}` : row?.toUser?.telegramUsername  
        default:
          if (!row?.fromUser) return ''
          return row?.fromUser?.firstName || row?.fromUser?.lastName ? `${row?.fromUser?.firstName || ''} ${row?.fromUser?.lastName || ''}` : row?.fromUser?.telegramUsername
      }
    },

    renderUserLink(row) {
      switch (row.type) {
        case transactionTypes.deposit:
        case transactionTypes.deposit_nft:
        case transactionTypes.referral:
        case transactionTypes.farming:
        case transactionTypes.clicker:
        case transactionTypes.task:
        case transactionTypes.daily_checkin:
          if (!row.toUser) return this.$router.push(`/user/${row.fromUser.id}`)
          return this.$router.push(`/user/${row.toUser.id}`)
        case transactionTypes.withdrawal:
        case transactionTypes.withdrawal_nft:
        case transactionTypes.transfer:
        case transactionTypes.deal:
        case transactionTypes.cheque:
          if (!row?.fromUser) return
          return this.$router.push(`/user/${row.fromUser.id}`)
        case transactionTypes.bonus:
          return this.$router.push(`/user/${row.toUser.id}`)
        default:
          return ''
      }
    },

    formatUrl(link) {
      if (!link) return ''

      const str = link.split('/').slice(-1)[0]
      return this.formatLongString(str)
    },

    getCurrency() {
      return this.waitRequest(() => {
        return this.fetchCryptoCurrency().catch(this.checkErrors)
      })
    },

    toUser(id) {
      this.$router.push(`/user/${id}`)
    },

    toTransaction(row) {
      if (row.countDeals === 0) return

      const address = row.address
      const assetId = row.coin.id

      this.$router.push({ name: 'wallet-transactions', query: { address, assetId } })
    },
  },
}
