<template>
  <b-form-group>
    <label for="datepicker-placeholder">{{ label }}</label>

    <v-select
      v-model="selectAsset"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :label="assetLabel"
      :options="assetOptionsList"
      :placeholder="placeholder"
      @search="searchAsset"
      v-b-tooltip.focus.v-primary
      title="Start writing to see options"
      variant="outline-primary"
    />
  </b-form-group>
</template>

<script>
import vSelect from 'vue-select'
import { BFormGroup, VBTooltip } from 'bootstrap-vue'
import { debounce } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import store from '@/store'
import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

export default {
  name: 'AssetFilter',
  components: {
    vSelect,
    BFormGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [waitRequest, validationError],
  props: {
    label: {
      type: String,
      default: 'Asset',
    },
    assetLabel: {
      type: String,
      default: 'labelName',
    },
    value: {
      type: [String, Object, Number],
    },
    placeholder: {
      type: String,
      default: 'Asset',
    },
    isCryptoCurrency: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      select: null,
      assetOptions: [''],
      searchedCurrencyList: [],
      fetchAction: this.fetchCryptoCurrency,
      searchAction: this.fetchSearchedCryptoCurrency,
    }
  },
  computed: {
    ...mapGetters({
      cryptoCurrency: 'cryptoCurrency/cryptoCurrency',
      fiatCurrency: 'fiatCurrency/fiatCurrency',
    }),

    selectAsset: {
      get() {
        return this.value
      },

      set(data) {
        this.select = data
        this.$emit('change', data)
        this.$emit('input', data)
      },
    },

    currency() {
      if (this.isCryptoCurrency) return this.cryptoCurrency

      return this.fiatCurrency
    },

    assetOptionsList() {
      const options = this.currency.value.map(currency => ({
        ...currency,
        labelName: `${currency.name} ${currency.network ? `/ ${currency.network}` : ''}`,
      }))

      return this.searchedCurrencyList.length ? this.searchedCurrencyList : options
    },

    direction() {
      return store.state.appConfig.isRTL
    },
  },

  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchSearchedCryptoCurrency: 'cryptoCurrency/fetchSearchedCryptoCurrency',
      fetchCryptoCurrency: 'cryptoCurrency/fetchCryptoCurrency',
      fetchSearchedFiatCurrency: 'fiatCurrency/fetchSearchedFiatCurrency',
      fetchFiatCurrency: 'fiatCurrency/fetchFiatCurrency',
    }),
    async initState() {
      if (!this.isCryptoCurrency) {
        this.fetchAction = this.fetchFiatCurrency
        this.searchAction = this.fetchSearchedFiatCurrency
      }

      await this.getCurrency()
    },

    searchAsset(asset) {
      this.searchCurrency(asset)
    },

    getCurrency() {
      return this.waitRequest(() => {
        return this.fetchAction().catch(this.checkErrors)
      })
    },

    searchCurrency: debounce(function(search) {
      return this.waitRequest(() => {
        return this.searchAction(search)
          .then(response => {
            this.searchedCurrencyList = response.data?.items || []
          })
          .catch(this.checkErrorsAlert)
      })
    }, 500),
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
